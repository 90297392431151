<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <a-btn-refresh @click="$refs.table.updateData()" />
    </template>
    <portal to="v-main">
      <s-Editor-editor
        v-if="getAccess('suData')"
        ref="suEdit"
        v-model="suEditShow"
        :config="{ table: 'accounting_record', filter: { id: idEdit } }"
        @show="suEditShow = true"
        :activate="true"
      />
    </portal>
    <a-table-f-api ref="table" :api="url" :model="model" :useQuery="true" :defaults="defaults" @click="onClickRow($event)"> </a-table-f-api>
  </static-fullscreen-card>
</template>

<script>
import { getAccess, genModel } from "@/components/mixings";

export default {
  mixins: [getAccess, genModel],
  components: {
    ///editDialog: () => import("./dialogs/eventDialog"),
  },
  data() {
    return {
      idEdit: 0,
      suEditShow: false,
      title: "",
      model: this.getModelList(this.$store.getters["config/get"].models.accRecords),
      url: "/accounting/records",
      defaults: {
        sort: { key: "id", order: "DESC" },
        paramName: "accRecords",
      },
    };
  },
  created() {
    this.$root.title = "Проводки";
  },
  computed: {
    permit() {},
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    onClickRow(e) {
      console.log(e);
      if (!this.$refs.suEdit) return;
      this.idEdit = e.row.id;
      this.suEditShow = true;
    },
  },
};
</script>
